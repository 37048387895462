"use client";

import ProductItem from "./productItem";
import { useEffect, useState } from "react";
import fetchGraphQL from "../helper/fetch";
import FilterMenu from "./filterSection";

async function GetProducts(limit: number = 12, skip: number = 0) {
  // Fetch 12 items
  const QUERY = `query {
        productCollection(limit: ${limit}, skip: ${skip}) {
            total,
            items {
                _id,
                name,
                price,
                discount,
                image {
                    url
                }
            }
        }
    }`;

  const result = await fetchGraphQL(QUERY);
  console.log("prod=>", result);
  return {
    total: result.data?.productCollection.total,
    products: result.data?.productCollection.items?.map((item: any) => {
      return {
        key: item._id,
        image: item.image.url,
        discount: item.discount,
        title: item.name,
        price: item.price,
      };
    }),
  };
}

export default function ProductSectionV2({ title }: { title: string }) {
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    search: "",
    priceRange: 0,
    brand1: "",
    brand2: "",
    brand3: "",
  });

  useEffect(() => {
    GetProducts().then((data: any) => {
      setTotal(data.total);
      setProducts(data.products);
    });
  }, []);

  console.log("products=>", products);

  const handleClick = () => {
    const newSkip = skip + 12; // Increase the skip by 12 to load the next set

    setSkip(newSkip);
    GetProducts(12, newSkip).then((data: any) => {
      // Fetch next 12 items
      setTotal(data.total);
      setProducts((prevProducts) => [...prevProducts, ...data.products]); // Append new products
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((old: any) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e: any) => {
    // TODO: filter parameters to be added in the URL. When someone refreshes the page, the filters will remain
  };

  return (
    <div id="products" className="products">
      <div className="shell md:px-[40px]">
        <div className="products__inner">
          <header className="products__head">
            <h2 className="font-bold ">{title}</h2>
          </header>

          <div className="grid grid-cols-4 gap-3">
            <div className={`flex mb-8 justify-center col-span-4`}>
              <div
                className={`h-fit grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4`}
              >
                {products.map((item) => (
                  <ProductItem key={item.key} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center mb-10">
          {total > products.length && (
            <div>
              <a onClick={handleClick} className="btn btn--outlined">
                Виж още
              </a>
            </div>
          )}
        </div>
        <hr />
      </div>
    </div>
  );
}
