"use client";

import Image from "next/image";
import PopupProduct from "./popupProduct";
import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function ProductItem({ item }: any) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const finalPrice =
    item.discount === null
      ? item.price
      : item.price - item.price * (item.discount / 100);

  console.log("item", item);
  return (
    <>
      {isOpen && <PopupProduct item={item} setIsOpen={setIsOpen} />}

      <div
        className="h-fit cursor-pointer hover:scale-105 transition-all duration-300"
        onClick={() =>
          router.push(`/products/${encodeURIComponent(item.title)}`)
        }
      >
        <div className=" h-[300px] lg:h-[300px] relative w-full product p-2 rounded-2xl border-[#eee] border-2 ">
          <div className="relative h-full w-full">
            <Image
              src={item.image}
              alt="product"
              // objectFit="cover"
              width={500}
              height={500}
              className="w-full h-full object-contain"
              // layout="fill"
            />
          </div>
        </div>

        <div className="text-[15px] font-semibold my-2">
          <Link href={`/products/${encodeURIComponent(item.title)}`}>
            {item.title}
          </Link>

          <div className="text-[20px] font-bold mt-2 flex justify-start gap-6">
            <p>{parseFloat(finalPrice).toFixed(2)} лв.</p>

            {!!item.discount && (
              <div className="flex items-center gap-2">
                <del className="text-gray-600">
                  {parseFloat(item.price).toFixed(2)} лв.         
                </del>

                <span className="text-xs bg-red-200 text-red-800 rounded-2xl flex items-center py-1 px-2">
                  -{item.discount}%
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
