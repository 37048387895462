"use client";

import ProductItem from "./productItem";
import { useEffect, useState } from "react";
import fetchGraphQL from "../helper/fetch";
import FilterMenu from "./filterSection";

// Fisher-Yates shuffle algorithm
function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

async function GetProducts(limit = 12, skip = 0) {
  // Fetch 12 products
  const QUERY = `query {
        productCollection(limit: ${limit}, skip: ${skip}) {
            total,
            items {
                _id,
                name,
                price,
                discount,
                image {
                    url
                }
            }
        }
    }`;

  const result = await fetchGraphQL(QUERY);
  return {
    total: result.data?.productCollection.total,
    products: result.data?.productCollection.items?.map((item: any) => {
      return {
        key: item._id,
        image: item.image.url,
        discount: item.discount,
        title: item.name,
        price: item.price,
      };
    }),
  };
}

export default function ProductSectionV1({ title }: { title: string }) {
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    search: "",
    priceRange: 0,
    brand1: "",
    brand2: "",
    brand3: "",
  });

  useEffect(() => {
    GetProducts().then((data: any) => {
      const shuffledProducts = shuffleArray(data.products); // Shuffle products
      setTotal(data.total);
      setProducts(shuffledProducts);
    });
  }, []);

  const handleClick = () => {
    const newSkip = skip + 12; // Increment skip by 12

    setSkip(newSkip);
    GetProducts(12, newSkip).then((data: any) => {
      const shuffledProducts = shuffleArray(data.products); // Shuffle new products
      setTotal(data.total);
      setProducts((prevProducts) => [...prevProducts, ...shuffledProducts]); // Append new shuffled products
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((old: any) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e: any) => {
    // TODO: filter parameters to be added in the URL. When someone refreshes the page, the filters will remain
  };

  return (
    <div id="products" className="products">
      <div className="shell md:px-[40px]">
        <div className="products__inner">
          <header className="products__head">
            <h2 className="font-bold">{title}</h2>
          </header>

          <div className="grid grid-cols-4 gap-3">
            <div className={`flex mb-8 justify-center col-span-4`}>
              <div
                className={`h-fit grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4`}
              >
                {products.map((item) => (
                  <ProductItem key={item.key} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center mb-10">
          {total > products.length && (
            <div className="">
              <a onClick={handleClick} className="btn btn--outlined">
                Виж още
              </a>
            </div>
          )}
        </div>
        <hr />
      </div>
    </div>
  );
}
